<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ApprehensionItems"
      :page.sync="page"
      :items-per-page="apprehensionItemsPerPage"
      :server-items-length="totalApprehensionItems"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Apprehension Items</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">Add Item</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Apprehension Item Name"
                      >
                      <v-text-field
                        v-model="editedApprehensionItem.name"
                        label="Name"
                        class="mt-n6"
                        outlined
                        dense
                        name="Apprehension Item Name"
                        :error-messages="errors"
                        color="grey"
                        filled
                      />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn class="success ml-4 mb-4" text @click="save">Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editApprehensionItem(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="apprehensionItemDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this Item ?</p>
          <v-btn link small color="blue" @click="deleteApprehensionItem">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectApprehensionItemsPerPage"
            v-model="apprehensionItemsPerPage"
            label="DeviantActs per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ApprehensionItem from '@/models/Items'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    apprehensionItemIndex: null,
    apprehensionItemItem: null,
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    ApprehensionItems: [],
    editedIndex: -1,
    editedApprehensionItem: new ApprehensionItem(),
    defaultItem: new ApprehensionItem(),
    selectApprehensionItemsPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    apprehensionItemsPerPage: 20,
    totalApprehensionItems: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'search'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.initialize()
      }
    },
    apprehensionItemsPerPage: {
      handler () {
        this.page = 1
        this.initialize()
      }
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      const pageNumber = this.page - 1
      this.$delinquentService.fetchAllItems(pageNumber, this.apprehensionItemsPerPage)
        .then(response => {
          if (!response.status) {
            this.ApprehensionItems = response.content
            this.totalApprehensionItems = response.totalElements
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editApprehensionItem (item) {
      this.editedIndex = this.ApprehensionItems.indexOf(item)
      this.$delinquentService.fetchSingleItem(item.id)
        .then(response => {
          if (response.id) {
            this.editedApprehensionItem = response
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    apprehensionItemDialog (item) {
      this.apprehensionItemIndex = this.ApprehensionItems.indexOf(item)
      this.apprehensionItemItem = item
      this.deleteDialog = true
    },

    deleteApprehensionItem () {
      this.deleteDialog = false
      this.$delinquentService.deleteItem(this.apprehensionItemItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.ApprehensionItems.splice(this.apprehensionItemIndex, 1)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedApprehensionItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    validate () {
      this.$refs.form.validate()
    },

    save () {
      this.validate()
      if (this.editedIndex > -1) {
        this.updateApprehensionItem()
      } else this.createApprehensionItem()
      this.close()
    },
    updateApprehensionItem () {
      // console.log(this.editedApprehensionItem)
      const deletedApprehensionItemArray = this.ApprehensionItems.splice(this.editedIndex, 1)
      this.$delinquentService.saveItems(this.editedApprehensionItem)
        .then(response => {
          if (response.id) {
            this.ApprehensionItems.splice(this.editedIndex, 1, response)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.ApprehensionItems.splice(this.editedIndex, 0, deletedApprehensionItemArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createApprehensionItem () {
      this.$delinquentService.saveItems(this.editedApprehensionItem)
        .then(response => {
          if (response.id) {
            this.ApprehensionItems.unshift(response)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.includes('CREATE_ITEMS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showCreate = true : this.showCreate = false
      this.privileges.includes('UPDATE_ITEMS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showEdit = true : this.showEdit = false
      this.privileges.includes('DELETE_ITEMS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
